// Import necessary dependencies and assets
import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import axios from "axios";
import thumbsUp from "../../src/assets/thumbsUp.svg";
import thumbsDown from "../../src/assets/thumbsDown.svg";
import thumbsBlackUp from "../../src/assets/thumbsBlackUp.svg";
import thumbsBlackDown from "../../src/assets/thumbsBlackDown.svg";
import Modal from "../component/Modal";
import FeedBack from "../../src/assets/feedBack.svg";
import { useNavigate } from "react-router-dom";
import LoadingGif from "../../src/assets/loadingGif.gif";
import LoadingGif2 from "../../src/assets/loadingGif2.gif";
import RightArrow from "../../src/assets/rightArrow.svg";
import ErrorIcon from "../../src/assets/error.png";
import Keycloak from "keycloak-js";
import baseUrl from "./config";

function Home() {
  // State variables using useState hooks
  const [inputValue, setInputValue] = useState("");
  const [inputNewValue, setInputNewValue] = useState("");
  const [apiResponses, setApiResponses] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [thumbUp, setThumbsUp] = useState(false);
  const [thumbDown, setThumbsDown] = useState(false);
  const [count, setCount] = useState();
  const [token, setToken] = useState(null);
  const [isAuthenticated, setAuthentication] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorSearch, setApiErrorSearch] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [keycloakValue, setKeycloakValue] = useState(null);
  const isRun = useRef(false);

  // Keycloak setup
  const keycloak = new Keycloak({
    url: "https://auth1.revealitsolutions.com:8443/",
    realm: "support_assistant",
    clientId: "support-assistant-client",
  });

  // useEffect hook to initialize Keycloak
  useEffect(() => {
    if (isRun.current) return;
    isRun.current = true;
    keycloak.init({ onLoad: "login-required", promiseType: "native" }).then((authenticated) => {
      if (authenticated) {
        setToken(keycloak.token);
        setAuthentication(authenticated);
        setKeycloakValue(keycloak);
      } else {
        console.log("Authentication failed");
      }
    });
  }, []);

  // Handle input change in the text box
  const handleInputChange = (event) => {
    setApiError(false);
    setInputValue(event.target.value);
  };

  // Handle "Enter" key press in the text box
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      callAPI();
    }
  };

  // Handle user logout
  const handleLogout = () => {
    setToken(null);
    setAuthentication(false);
    keycloakValue.logout();
  };

  // Function to call the API and fetch responses
  const callAPI = () => {
    setLoading(true);
    const data = {
      Prompt: inputValue,
      OrgID: 2,
      User: "arvind",
      Context: "Query",
    };
    if (inputValue.length >= 3) {
      axios
        .post(`${baseUrl.url}/prompt`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setApiResponses([...apiResponses, response.data.ChatResponse.choices]);
          setInputNewValue(inputValue);
          setThumbsDown(false);
          setThumbsUp(false);
          setInputValue("");
          if (response.status === 200) {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.status === 400) {
            setLoading(false);
            setApiError(true);
            setApiErrorSearch("Length must be less than or equal to 300 characters long");
          } else if (error.response && error.response.status === 403) {
            setLoading(false);
            setApiError(true);
            setApiErrorSearch("Oops! Something went wrong. Please try again.");
          } else {
            setApiErrorSearch("Oops! Something went wrong. Please try again.");
            console.log(error, "prompt");
            setApiError(true);
            setLoading(false);
          }
        });
    } else {
      alert("Please enter at least three words.");
      setLoading(false);
    }
  };

  // Function to handle the feedback modal
  const onClickFeedBack = () => {
    setIsOpen(true);
  };

  // Data for thumbs-up and thumbs-down API
  const thumsData = {
    Prompt: inputNewValue,
    OrgID: 2,
    User: "arvind",
  };

  // Function to handle the thumbs-up button click
  const onClickThumbsUp = (item, index) => {
    axios
      .post(`${baseUrl.url}/thumbsup`, thumsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response thumbsup:", response);
          setCount(response.data);
          setThumbsUp(true);
          setThumbsDown(false);
        }
      })
      .catch((error) => console.log(error));
  };

  // Function to handle the thumbs-down button click
  const onClickThumbsDown = () => {
    axios
      .post(`${baseUrl.url}/thumbsdown`, thumsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCount(response.data);
          setThumbsDown(true);
          setThumbsUp(false);
        }
      })
      .catch((error) => console.log(error));
  };
  if(token ==null) {
    // setLoading(true)

    return (
      <div className="containeLoader">
    <div class="loader"></div>
    </div>)
  }
  return (
    <div className="App">
      {/* Header */}
      <div className="login-header">
        <div className="header-assistant">ReveallT Support Assistant</div>
        <button className="logout-button" onClick={handleLogout}>
          Sign Out
        </button>
      </div>

      <header className="App-header">
        <div className="top-container">
          <div className="top-sub-container">
            {!apiError ? (
              // Rendering API responses as cards
              apiResponses.map((item, index) => {
                return (
                  <div key={index} className="card">
                    <div className="inputBorder">
                      <div className="response-right-container">
                        {item.map((newItem) => {
                          const markdown = newItem.message?.content;
                          return <div key={index}>{markdown}</div>;
                        })}
                      </div>
                      <div className="thumbs-container">
                        <div className="thumb-container">
                          {apiResponses.length - 1 === index && (
                            <>
                              <img
                                onClick={() => onClickThumbsUp(item, index)}
                                src={!thumbUp ? thumbsUp : thumbsBlackUp}
                                className="thumbs-image"
                              />
                              <div style={{ fontSize: "15px" }}>
                                {count?.ThumbsUp}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="thumb-container">
                          {apiResponses.length - 1 === index && (
                            <>
                              <img
                                onClick={() => onClickThumbsDown()}
                                src={!thumbDown ? thumbsDown : thumbsBlackDown}
                                className="thumbs-image"
                              />
                              <div style={{ fontSize: "15px" }}>
                                {count?.ThumbsDown}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="feedback-container">
                      {apiResponses.length - 1 === index && (
                        <button
                          onClick={() => onClickFeedBack()}
                          className="button-feedback"
                        >
                          <div> Feedback </div>
                          <img src={FeedBack} className="feedback-image" />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              // Error message in case of API error
              <div className="error-container">
                <img className="error-icon" src={ErrorIcon} alt="ErrorIcon" />
                <p>{apiErrorSearch}</p>
              </div>
            )}
            {/* Loading GIF */}
            <div className="arrow-containerGif">
              {loading && (
                <img
                  className="gifStyle2"
                  src={LoadingGif2}
                  alt="Example GIF"
                />
              )}
            </div>
          </div>
          {/* Feedback Modal */}
          {isOpen && (
            <Modal
              setIsOpen={setIsOpen}
              inputValue={inputNewValue}
              setInputValue={setInputValue}
            />
          )}
        </div>

        {/* Input text box and submit button */}
        <div className="bottom-container">
          <div className="input-container">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Send a message..."
              className="input"
            />
            <div className="arrow-container">
              {!loading ? (
                <button className="submit-button" onClick={callAPI}>
                  <div> SUBMIT</div>
                  <img className="right-arrow-button" src={RightArrow} />
                </button>
              ) : (
                <img className="gifStyle" src={LoadingGif} alt="Example GIF" />
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Home;
