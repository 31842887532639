import React, { useState } from "react";
import styles from "./Modal.module.css";
import thumbsUp from "../../src/assets/thumbsBlackUp.svg";
import axios from "axios";

const Modal = ({ setIsOpen, inputValue }) => {
  const [feedback, setFeedback] = useState("");

  const handleChange = (event) => {
    setFeedback(event.target.value);
  };
  console.log("Prompt:", inputValue, "feedback:", feedback);
  const onClickSubmitFeedback = () => {
    const body = {
      Prompt: inputValue,
      FeedbackText: feedback,
      OrgID: 2,
      User: "arvind",
    };
    axios
      .post("http://18.119.5.136:3001/feedback", body)
      .then((response) => {
        console.log(response, "response.feedback");
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setIsOpen(false);
  };
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <img src={thumbsUp} className={styles.thumbsImage} />

            <h5 className={styles.heading}>Provide Additional Feedback</h5>
          </div>
          <div className={styles.line}></div>
          <div className={styles.inputMainContainer}>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={feedback}
                onChange={handleChange}
                placeholder="What do you like about the response?"
                className={styles.input}
              />
            </div>
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
              <button
                className={styles.deleteBtn}
                onClick={() => onClickSubmitFeedback()}
              >
                Submit Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
