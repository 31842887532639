import React from "react";
import RouteScreen from "./Routes/routes-public";
const App = () => {
  return (
    <div>
      <RouteScreen />
    </div>
  );
};

export default App;
