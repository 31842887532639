import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Screens/Home";
import Signup from "../Screens/Signup";
import ForgetPassword from "../Screens/ForgetPassword";
import CreatePassword from "../Screens/CreatePassword";

const RouteScreen = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        {/* <Route exact path="/login" element={<Login />}></Route> */}
        {/* <Route exact path="/signup" element={<Signup />}></Route> */}
        {/* <Route
          exact
          path="/forgetpassword"
          element={<ForgetPassword />}
        ></Route> */}
        {/* <Route
          exact
          path="/createpassword"
          element={<CreatePassword />}
        ></Route> */}
      </Routes>
    </Router>
  );
};

export default RouteScreen;
